import React from 'react'
import 'animate.css/animate.css'
import Layout from '../components/Layout'
import Login from '../components/Login'

const IndexPage = () => {
  return (
    <Layout>
      <Login />
    </Layout>
  )
}

export default IndexPage
